import React, { useEffect, useState } from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import CirclesSVG from "../../assets/svg/ecan/circles.svg"

import Section6content from "../../assets/svg/ecan/content.svg"

import IpadVideo from "../../assets/images/ecan/ecan-ipad.mp4"

import "./template-styles.scss"
import "./ecan.scss"
import Img from "../../assets/images/ecan-images"

import { gsap, Power2, Power4, Elastic, Back } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import useScrollDirection from "../../hooks/useScrollDirection"
import { motion } from "framer-motion"

import scrollTo from "../../animations/scrollTo"
import NavigationOrbs from "../../components/NavigationOrbs"
import SEO from "../../components/seo"

gsap.registerPlugin(ScrollTrigger)

// import ipadVideo from

const black = "#212931"
const white = "#ffffff"
const peach = "#F48060"
const aqua = "#3D838B"
const mint = "#5EC199"

const teal = "#3D6078"

const EcanProject = () => {
  ProjectAnimations()

  // section 3 animations
  useEffect(() => {
    ScrollTrigger.saveStyles(".slides-container")
    ScrollTrigger.matchMedia({
      "(min-width: 769px)": () => {
        const vw = n => (window.innerWidth / 100) * n
        const vh = n => (window.innerHeight / 100) * n
        const tl = new gsap.timeline({ paused: true })
          .add("start")
          // big1
          .from(
            "#big1",
            { y: () => "-=" + vh(70), duration: 2.5, ease: "power4.out" },
            "start"
          )
          .from(
            "#big1",
            { x: () => "-=400", duration: 2.5, ease: "back.inOut" },
            "start"
          )
          // big2

          .from(
            "#big2",
            { x: () => "+=" + vw(35), duration: 3, ease: "power4.out" },
            "start"
          )
          .from(
            "#big2",
            { y: () => "+=400", duration: 3, ease: "back.inOut" },
            "start"
          )
          // big3
          .from(
            "#big3",
            { x: () => "-=" + vw(35), duration: 3.5, ease: "power4.out" },
            "start"
          )
          .from(
            "#big3",
            { y: () => "+=200", duration: 3.5, ease: "back.inOut" },
            "start"
          )
          // small1
          .from(
            "#small1",
            { x: () => "+=" + vw(35), duration: 3.1, ease: "power4.out" },
            "start+=0.4"
          )
          .from(
            "#small1",
            { y: () => "-=200", duration: 3.1, ease: "back.inOut" },
            "start+=0.4"
          )
          // small2
          .from(
            "#small2",
            { x: () => "-=" + vw(35), duration: 3.3, ease: "power4.out" },
            "start+=0.4"
          )
          .from(
            "#small2",
            { y: () => "-=200", duration: 3.3, ease: "back.inOut" },
            "start+=0.4"
          )
          // small3
          .from(
            "#small3",
            { y: () => "+=" + vh(60), duration: 3.3, ease: "power4.out" },
            "start+=0.8"
          )
          .from(
            "#small3",
            { x: () => "-=200", duration: 3.3, ease: "back.inOut" },
            "start+=0.8"
          )

        const STstart = ScrollTrigger.create({
          trigger: ".section-2-wrapper",
          start: "top center",
          onEnter: () => tl.play(),
        })
        const STreset = ScrollTrigger.create({
          trigger: ".section-2-wrapper",
          start: "top bottom",
          onLeaveBack: () => tl.pause(0),
        })
      },
    })
  }, [])

  // section 5 animations
  useEffect(() => {
    const mint = "#5EC199"
    const lightBlue = "#6092B6"
    const tan = "#BB8B53"
    const brown = "#A26F58"
    const peach = "#F48060"
    const orange = "#E05E46"
    const aqua = "#3D838B"

    gsap.defaults({
      ease: "power2.easeInOut",
      ease: "linear",
    })

    ScrollTrigger.saveStyles(".slides-container")
    ScrollTrigger.matchMedia({
      "(min-width: 769px)": () => {
        const tl = new gsap.timeline({ paused: true })
          .fromTo(
            ".slides-container",
            {
              x: "0%",
              backgroundColor: mint,
            },
            {
              duration: 3,
              x: -(100 / 7) * 1 + "%",
              backgroundColor: lightBlue,
            },
            "+=1.5"
          )
          .to(
            ".slides-container",
            {
              duration: 3,
              x: -(100 / 7) * 2 + "%",
              backgroundColor: tan,
            },
            "+=1.5"
          )
          .to(
            ".slides-container",
            {
              duration: 3,
              x: -(100 / 7) * 3 + "%",
              backgroundColor: brown,
            },
            "+=1.5"
          )
          .to(
            ".slides-container",
            {
              duration: 3,
              x: -(100 / 7) * 4 + "%",
              backgroundColor: peach,
            },
            "+=1.5"
          )
          .to(
            ".slides-container",
            {
              duration: 3,
              x: -(100 / 7) * 5 + "%",
              backgroundColor: orange,
            },
            "+=1.5"
          )
          .to(
            ".slides-container",
            {
              duration: 3,
              x: -(100 / 7) * 6 + "%",
              backgroundColor: aqua,
            },
            "+=1.5"
          )

        ScrollTrigger.create({
          trigger: ".section-5-wrapper",
          start: "top top",
          //   end: "+=7000",
          end: () => "+=" + window.innerHeight * 6,
          scrub: 0.3,
          animation: tl,
        })
      },
    })
  }, [])

  // section 6 animations
  useEffect(() => {
    const topContent = document.querySelector(
      "#section-6-svg-content #top_content"
    )
    const bottomContent = document.querySelector(
      "#section-6-svg-content #bottom_content"
    )
    // gsap.set(bottomContent, { opacity: 0 })

    ScrollTrigger.saveStyles(topContent, bottomContent)
    ScrollTrigger.matchMedia({
      "(min-width: 769px)": () => {
        // gsap.set(bottomContent, { opacity: 0 })

        // const tl = gsap
        //   .timeline({ paused: true })
        //   .set(bottomContent, { opacity: 0 })
        //   .add("start")
        //   .to(topContent, { opacity: 0 })
        //   .to(bottomContent, { opacity: 1 })
        //   .set(topContent, { opacity: 0 })

        // ScrollTrigger.create({
        //   trigger: ".section-6-wrapper",
        //   start: "center top",
        //   onEnter: () => tl.play(),
        //   onEnterBack: () => tl.reverse(),
        // })
        gsap.set(topContent, { y: "-11357", opacity: "" })
        gsap.set(bottomContent, { opacity: "" })

        const st1 = ScrollTrigger.create({
          trigger: ".section-6-wrapper",
          start: "center top",
          end: "+=99999",
          toggleClass: {
            targets: [topContent, bottomContent],

            className: "scrolled",
          },
        })
      },
      "(max-width: 768px)": () => {
        gsap.set(topContent, { opacity: 1, y: "-11697" })
        gsap.set(bottomContent, { opacity: 1 })
      },
    })
  }, [])

  // section 7 animatons
  useEffect(() => {
    const ipadScreens = document.querySelectorAll(".section-7 .screen")

    const delayOffset = 800

    ipadScreens.forEach((screen, index) => {
      const st = ScrollTrigger.create({
        trigger: ".section-7-wrapper",
        start: () => `top+=${delayOffset * index + window.innerHeight} bottom`,
        end: "+=9999",
        toggleClass: { targets: screen, className: "active" },
      })
    })
  }, [])

  return (
    <div className="project ecan">
      <SEO
        title="Ecan"
        description="Cantabrians know that Crown entities and local government work on behalf of
        the people they serve. We also know that a portion of our rates
        goes to Environment Canterbury."
      />

      <NavigationOrbs />
      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ecan6" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: teal, color: white }}
        >
          <div>
            <h1>Ecan</h1>
            <p>
              Cantabrians know that Crown entities and local government work on
              behalf of the people they serve. They also know that a portion of
              our rates goes to Environment Canterbury. But knowing what they’re
              paying for is another matter. We helped Ecan tell the story.
              <br />
              <br /> Strategy, wireframing, UI & UX design.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-1">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ecan2" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: peach, color: teal }}
        >
          <div>
            <p>
              Working with our digital geography client Orbica, we designed an
              engaging, interactive user interface for a digital tool for ECan.
              The objective is to show how rates are used to fund important
              environment and community projects throughout Canterbury. The tool
              presents data through dynamically-proportioned 'bubbles' and a
              clean, simple format that makes information legible and easy to
              absorb.
            </p>
          </div>
        </div>
      </Slide>
      <div className="section-2-wrapper ">
        <Slide className="section-2">
          <Spacer />
          <div
            className="panel--w-half panel--h-screen panel--image panel--image--centered"
            style={{ backgroundColor: teal }}
          >
            <CirclesSVG />
            {/* <Img src="logo" className="logo" /> */}
          </div>
        </Slide>
      </div>
      <Slide className="section-3">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ecan1" />
        </div>
      </Slide>
      <Slide className="section-4">
        <div
          className="ipad panel--w-full panel--h-screen screen-container"
          style={{ backgroundColor: teal }}
        >
          <div className="screen-container__inner-container">
            <Img src="ecan3" className="screen-container__frame" />
            <div className="screen-container__screen">
              <video src={IpadVideo} autoPlay playsInline muted loop></video>
            </div>
          </div>
        </div>
      </Slide>
      <div className="section-5-wrapper ">
        <Slide className="section-5">
          <div
            className="panel--w-half panel--h-screen panel--text centered-content"
            style={{ backgroundColor: teal, color: white }}
          >
            <div>
              <p>
                A simple line-drawn design for the icon-set divides sections and
                pulls content together.
              </p>
            </div>
          </div>

          <div className="animation-container panel--w-half">
            <div
              className="slides-container z-10"
              style={{ backgroundColor: mint }}
            >
              <div className="slide">
                <Img src="ecan7a" />
              </div>
              <div className="slide">
                <Img src="ecan7b" />
              </div>
              <div className="slide">
                <Img src="ecan7c" />
              </div>
              <div className="slide">
                <Img src="ecan7d" />
              </div>
              <div className="slide">
                <Img src="ecan7e" />
              </div>
              <div className="slide">
                <Img src="ecan7f" />
              </div>
              <div className="slide">
                <Img src="ecan7g" />
              </div>
            </div>
          </div>
        </Slide>
      </div>

      <div className="section-6-wrapper ">
        <Slide className="section-6">
          <div className="panel--w-half panel--h-screen panel--image panel--image--centered bg-white">
            <Img src="ecanbubbles" />
          </div>
          <div
            className="svg-content panel--w-half panel--h-screen panel--text centered-content"
            style={{ backgroundColor: teal }}
          >
            <Section6content />
          </div>
        </Slide>
      </div>

      <div className="section-7-wrapper ">
        <Slide className="section-7">
          <div
            className="ipad panel--w-full panel--h-screen screen-container"
            style={{ backgroundColor: teal }}
          >
            <div className="screen-container__inner-container">
              <Img src="ecan4" className="screen-container__frame" />
              <div className="screen-container__screen">
                {/* <video src={IpadVideo} autoPlay playsInline muted loop></video> */}
                <div className="screen">
                  <Img src="ecan5a" />
                </div>
                <div className="screen">
                  <Img src="ecan5b" />
                </div>
                <div className="screen">
                  <Img src="ecan5c" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>

      <Slide className="section-16">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: peach, color: teal }}
        >
          <div>
            <p>
              By articulating positive outcomes for public spend, the rates tool
              is playing an innovative part in engaging audiences, creating
              transparency and encouraging trust.
              <br />
              <br />
              <TransitionLink
                to="/projects/double-o/"
                color="#dd574c"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default EcanProject

const vw = n => (window.innerWidth / 100) * n
const vh = n => (window.innerHeight / 100) * n
