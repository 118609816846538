import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const TemplateImages = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      ecan1: file(relativePath: { eq: "ecan/ecan-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan2: file(relativePath: { eq: "ecan/ecan-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan3: file(relativePath: { eq: "ecan/ecan-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan4: file(relativePath: { eq: "ecan/ecan-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan5a: file(relativePath: { eq: "ecan/ecan-5a.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan5b: file(relativePath: { eq: "ecan/ecan-5b.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan5c: file(relativePath: { eq: "ecan/ecan-5c.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan6: file(relativePath: { eq: "ecan/ecan-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      ecan7a: file(relativePath: { eq: "ecan/biodiversity@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan7b: file(relativePath: { eq: "ecan/freshwater@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan7c: file(relativePath: { eq: "ecan/airquality@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan7d: file(relativePath: { eq: "ecan/hazard@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan7e: file(relativePath: { eq: "ecan/leadership@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan7f: file(relativePath: { eq: "ecan/transport@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecan7g: file(relativePath: { eq: "ecan/portfolios@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ecanbubbles: file(relativePath: { eq: "ecan/bubbles@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  const eagerImages = [
    "ecan7a",
    "ecan7b",
    "ecan7c",
    "ecan7d",
    "ecan7e",
    "ecan7f",
    "ecan7g",
  ]

  const loading = eagerImages.includes(src) ? "eager" : "lazy"

  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
      loading={loading}
    />
  )
}
export default TemplateImages
